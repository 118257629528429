/* .underConstructionText {
	color: red;
    font-size: 40px;
    font-family: Arial;
    text-align: center;
	margin-top: 100px;
} */
/* .presentToYou {
	font-size: 30px;
	font-family: Arial;
	text-align: center;
} */
/* .imagesConatiner {
  margin: auto;
  width: 50%;
  border: 2px solid gray;
  margin-bottom: 100px;;
} */

/* .images {
  width: 100%;
} */

/* .heder{
	border-bottom: 1px solid black;
    color: white;
    font-size: 24px;
    font-family: Arial;
    padding-top: 2px;
    position: fixed;
    top: 0px;
} */

/* .futter{
	border-top: 1px solid black;
    color: white;
    font-size: 18px;
    font-family: Arial;
    padding-top: 2px;
    position: fixed;
    bottom: 0px;
} */

/* .hederText{
	position: absolute;
    bottom: 7%;
    text-align: center;
    width: 100%;
} */

body {
  overscroll-behavior: contain;
  overscroll-behavior: none;
  overflow: hidden;
}

#root {
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.preventSelect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.hederFontSize{
  font-size: 16px;
}

.heder{
	width: 100%;
	height: 40px;
	background-color: #642d91;
  transform: 'translate(-50%, -50%)';
}
.futter{
	width: 100%;
	height: 40px;
	background-color: #642d91;
}

.topOffset{
  position: fixed;
  margin-top: 42px;
  width: calc(100% - 40px);
  height: calc(100% - 190px);
  padding: 20px;
  overflow: auto;
  overflow-x: hidden;
  /* background-color: red; */
}

.boottomGradientMask{
  position: fixed;
  height: 30px;
  bottom: 108px;
  width: 100%;
  background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
}

.bottomMarginElement{
    position: relative;
    float: left;
    /* background-color: red; */
    width: 100%;
    height: 50px;
}

.boottomOffset{
  margin-bottom: 150px;
}

.price{
  position: absolute;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 3px;
  background-color: rgba(100, 45, 145, 0.6);
  width: 130px;
  height: 21px;
  top: 11px;
  right: 19px;
  border-radius: 24px 0px 0px 24px;
  color: white;
  border: 1px solid white;
}
.priceVodica{
  background-color: rgba(72, 73, 119, 0.6);
}

.order{
  color: red;
}

.productSeryesStyle{
  font-size: 24px;
  text-align: center;
  width: 100%;
  float: inline-end;
}
.productSeryesStyleAdellaSunny{
  color: #642d91;
}
.productSeryesStyleVoditsa{
  margin-top: 30px;
  color: #484977;
}

.productSeparatorStyle{
  padding-bottom: 20px;
  text-align: center;
}

.productSepparatorImg{
  height: 2px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.productListContiner{
  float: left;
  width: 200px;
  position: relative;
  cursor: pointer;
  margin: 5px
}

.productCategoryContiner{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}
.lastProductCategoryContiner{
  margin: auto;
  margin-left: 2%;
  width: 100%;
}

.productListImg{
  display: block;
  /* margin-left: auto;
  margin-right: auto; */
  /* width: 70%; */
  border-radius: 0px 24px 0 24px;
  width: -webkit-fill-available;
  width: -moz-available;
  border: 1px solid #642d91;
}

.productListLabelContainer{
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 40px;
  border-radius: 0 0 0 24px;
  background-color: rgba(100, 45, 145, 1);
}

.productListLabelContainerBlue{
  background-color: rgba(72, 73, 119, 1);
}

.productListLabel{
  position: absolute;
  width: 96%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -49%);
  font-size: 13px;
  color: white;
}

.productContainer{
  display: flex;
  /* margin-bottom: 100px; */
}

.productImdAndTitle{ 
  padding-left: 10px;
}

.productImgBackground{
  max-width: 200px;
  border-radius: 15px 0 15px 0;
}
 .productText{
  padding-left: 2%;
  padding-right: 2%;
 }

.productImg{
  position: absolute;
}

.productImgContianer{
  position: relative;
  width: 210px;
  height: 380px;
}

.productShopImg{
  margin-left: 147px;
  margin-top: 3px;
  width: 50px;
  cursor: pointer;
}

.infoIcon{
  position: absolute;
  top: 2%;
  right: 4%;
  width: 17%;
  border: 2px solid white;
  border-radius: 100%;
}

.homeImage{
  border-radius: 30px 0px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.floatingImages{
  position: fixed;
  bottom: 40px;
  right: 1%;
  z-index: 100;
}

.homeTextStyle{
    font-size: 20px;
    font-family: 'Roboto-Regular';
    color: #333;
}
.homesubTitle{
  color: #9d9494
}

.meetUs{
  color: #818181
}

.productBuyLinkText{
  color: black;
  text-decoration: none;
  margin-bottom: 150px;
}

.productBuyLinkTextActive{
  color: -webkit-link;
  text-decoration: underline;
}

/* @font-face {
  font-family: "Neucha-Regular";
  src: url("./fonts/Neucha-Regular.ttf") format("truetype");
} */
@font-face {
  font-family: "Roboto-Bold";
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}
/* @font-face {
  font-family: "Roboto-Italic";
  src: url("./fonts/Roboto-Italic.ttf") format("truetype");
} */
@font-face {
  font-family: "Roboto-Regular";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}

@media only screen and (max-width: 500px) {
  .productListContiner{
    width: 170px;
  }
  .productListLabel {
    font-size: 12px;
  }
  .productListImg {
    border-radius: 0px 14px 0 14px;
  }
  .productListLabelContainer{
    border-radius: 0 0 0 14px;
    height: 35px;
  }
  .price{
    font-size: 11px;
    width: 100px;
    height: 16px;
  }
  
.hederFontSize{
  font-size: 14px;
}
}

@media (orientation: landscape) {
  .portraitHidenElement{
    display: none;
  }
}
@media (orientation: portrait) {
  .landscepaeHidenElement{
    display: none;
  }
  .productContainer{
    display: block;
  }
  .productImdAndTitle{
    margin: auto;
    text-align: center;
  }

  .productShopImg{
    margin-left: 71px;
  }

  .productImgContianer{
    width: 100%;
  }

  .productImg{
    position: absolute;
    transform: translate(-50%, 0%);
  }

  .productText{
    text-align: left;
  }
  .homeImage{
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(6%, 0%);
    width: 90%;
  }
  .flatingItgemsOffset{
    top: 10%
  }
}





#cf4a  {
  position: relative;
  /* height: 281px; */
  width: 450px;
  /* margin: 0 auto; */
}


@keyframes cf4FadeInOut {
  0% {
    opacity:1;
  }
  17% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  92% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

#cf4a img:nth-of-type(1) {
  animation-delay: 6s;
}
#cf4a img:nth-of-type(2) {
  animation-delay: 4s;
}
#cf4a img:nth-of-type(3) {
  animation-delay: 2s;
}
#cf4a img:nth-of-type(4) {
  animation-delay: 0;
}
#cf4a img {
  position: absolute;
  left: 0;
}

#cf4a img {
  -webkit-animation-name: cf4FadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 8s;
  -moz-animation-name: cf4FadeInOut;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 8s;
  -o-animation-name: cf4FadeInOut;
  -o-animation-timing-function: ease-in-out;
  -o-animation-iteration-count: infinite;
  -o-animation-duration: 8s;
  animation-name: cf4FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 8s;
}

body{
  margin: 0px;
  padding: 0px;
}